import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {common, getapi, messager, logout} from '../common/functions';
import Cookies from 'universal-cookie';
import {read, utils} from 'xlsx'

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Swal from 'sweetalert2'


const cookies = new Cookies();
const theme = createTheme();




  class Main extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        json:[],
      };
      this.readUploadFile = this.readUploadFile.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
    readUploadFile(e){
      e.preventDefault();
      if (e.target.files) {
          const reader = new FileReader();
          reader.onload = (e) => {
              const data = e.target.result;
              const workbook = read(data, { type: "array" });
              const sheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[sheetName];
              var json = utils.sheet_to_json(worksheet);
              json = messager(json);
              this.setState({json:json});
              
          };
          reader.readAsArrayBuffer(e.target.files[0]);
      }
    }
    async handleSubmit(e){
      e.preventDefault();
      var data = {sms:[]};
      this.state.json.map(js=>{
        var temp = {
          phone:js["Parents' Number"],
          message:js['message']
        }
        data.sms.push(temp)
      })
      data.sms = JSON.stringify(data.sms);
      console.log(JSON.stringify(data));
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+common.token,
        },
        body: JSON.stringify(data),
      };
      const url = common.api + 'sms/send/';
      const response = await fetch(url, requestOptions);
      const response_code = response.status;
      const data_response = await response.json();
      if(response_code===201){
        Swal.fire(
          'Good job!',
          'The sms has been sent!',
          'success'
        ).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "../main";
          } 
        })
      }
      else{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "../main";
          } 
        })        
      }
    }
    render() {
      const {json} = this.state;
      return (
        <div>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
              <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                >
                </IconButton>
                <Button color="inherit" onClick={()=>(logout())}>Log out</Button>
              </Toolbar>
            </AppBar>
          </Box>
        <div className='block d-flex flex-column justify-content-center container'>
            <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                <Typography component="h1" variant="h5">
                    Please upload the .xlsx file
                </Typography>
                <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
                  <Button
                    variant="outlined"
                    component="label"
                    fullWidth
                    sx={{ mt: 3, mb: 1 }}
                    onChange={this.readUploadFile}
                  >
                    Upload File
                    <input
                      type="file"
                      hidden
                    />
                  </Button>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    endIcon={<SendIcon />}
                    >
                    Send
                  </Button>
                </Box>
                </Box>
            </Container>
            </ThemeProvider>
            <table>
              <thead>
                <tr>
                  <th>№</th>
                  <th>Phone number</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                {
                  json.map((j, i) => (
                    <tr key={i+1}>
                      <th>{i+1}</th>
                      <th>{j["Parents' Number"]}</th>
                      <th>{j['message']}</th>
                    </tr>
                  ))
                }
            </tbody>
            </table>
        </div>
        </div>
      );
    }
  }
  export default Main;
