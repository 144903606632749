import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './common/main.css'
import React from 'react';
import Cookies from 'universal-cookie';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Login from './views/login';
import Main from './views/main';
const cookies = new Cookies();
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount(){
    var token= cookies.get('token');
    this.setState({token:token});
  }
  render() {
var {token} = this.state;
    return (
      <Router>
        <div> 
          <Route exact path="/" component={Login} />
          <Route path="/main/" component={Main} />
        </div>
    </Router>
    );
  }
}
export default App;
